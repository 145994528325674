@import url("https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:300,400|Fira+Sans:300,400&display=swap");

@tailwind base;
@tailwind components;

body {
  background-color: #f2f3e4;
}

.pr-hero-image {
  background-image: url(./images/bg-mobile.jpg);
  background-position: center center;
  background-size: 100vw;
  height: 70vw;
}

@media (min-width: 640px) {
  .pr-hero-image {
    background-image: url(./images/bg.jpg);
    background-position: center top;
    background-size: 100vw;
    background-attachment: fixed;
    height: 40vw;
  }
}

.pr-hero-logo {
  display: block;
  width: 200px;
  margin-left: -10px;
  transform: translateY(-35%);
}

@media (min-width: 640px) {
  .pr-hero-logo {
    width: 278px;
    height: 238px;
    margin: 0;
    transform: translateY(-35%);
  }
}

.pr-features {
  background-color: #ceddc8;
}

.pr-h2 {
  @apply antialiased font-display text-4xl leading-tight mb-8;
}

.pr-para {
  @apply font-body font-light text-lg my-4;
}

.pr-counters {
  background-color: #ceddc8;
}

.pr-counter {
  @apply font-display font-light text-5xl leading-none text-center;
}

.pr-counter-text {
  @apply font-body font-light text-base mt-4 leading-normal text-center;
}

.tmember {
  filter: grayscale(25%);
  width: 20vw;
  height: 20vw;
}

@media (min-width: 640px) {
  .tmember {
    width: 10vw;
    height: 10vw;
  }
}

@media (min-width: 1440px) {
  .tmember {
    width: 5vw;
    height: 5vw;
  }
}

@tailwind utilities;
